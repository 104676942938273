<script lang="ts">
	import { theme } from '$lib/stores/theme';
	import ThemeController from '$lib/components/ThemeController.svelte';
	import Icon from '@iconify/svelte';
</script>

<div class="w-screen h-16 bg-base-200">
	<header class="container px-4 mx-auto navbar">
		<a href="/" class="btn btn-ghost text-2xl font-semibold">
			<enhanced:img src="../../assets/logo.png?enhanced&w=24&h=24" alt="Last1372" />
			<span>Last<span class="font-mono text-orange-500 tracking-tighter font-bold">1372</span> </span>
		</a>

		<div class="ml-auto flex items-center">
			<button type="button" class="btn btn-sm btn-ghost btn-circle mr-2" on:click={theme.toggle}>
				<ThemeController />
			</button>

			<a
				href="/rss.xml"
				target="_blank"
				class="hidden sm:inline-flex btn btn-sm btn-circle mr-2"
				aria-label="Apri il feed RSS"
			>
				<Icon icon="mingcute:rss-fill" width="20" height="20" />
			</a>

			<a
				href="https://facebook.com/last1372"
				class="hidden sm:inline-flex btn btn-sm btn-ghost btn-primary"
			>
				<!-- <Icon icon="solar:rocket-bold-duotone" width="20" height="20" /> -->
				<span> Segui su Facebook </span>
			</a>
		</div>
	</header>
</div>
