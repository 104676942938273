<script lang="ts">
	import { isDark } from '$lib/stores/theme';
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';

	let className = '';
	export { className as class };
</script>

<span class="sr-only">Toggle theme</span>
<div class={cn('swap swap-rotate', $isDark && 'swap-active', className)}>
	<div class="swap-off fill-current">
		<Icon icon="solar:sun-fog-bold" width="20" height="20" />
	</div>

	<div class="swap-on fill-current">
		<Icon icon="solar:moon-fog-bold" width="20" height="20" />
	</div>
</div>
